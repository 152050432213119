import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../Layout';
import SEO from '../SEO';

import commonPageStyles from '../../styles/commonPage.module.scss';
import styles from './about.module.scss';

const About = () => (
  <StaticQuery
    query={graphql`
      query {
        image1: file(relativePath: { eq: "about/b_e.png" }) {
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const { image1 } = data;
      return (
        <Layout>
          <SEO title="About Us | Autism Parenting Journey" pathname="/about" />
          <section className={commonPageStyles.pageWrapper}>
            <div className={commonPageStyles.mainContent}>
              <div className={commonPageStyles.sectionHeaderWrapper}>
                <h1 className={commonPageStyles.sectionHeader}>About Us</h1>
              </div>

              <Img
                fluid={image1.childImageSharp.fluid}
                alt="Blake + Emily - Autism Parenting Journey"
                className={styles.image}
              />

              <p>
                We are Blake and Emily from Utah and we are the parents of 4 kids, two of which have
                been diagnosed with autism spectrum disorder (ASD). Our oldest daughter was
                diagnosed with ASD at age 8, and also has been diagnosed with severe depression,
                severe anxiety, and ADHD. Our son was diagnosed with ASD at age 5, and both of our
                ASD kids are considered “high-functioning.”
              </p>

              <p>
                We choose to share our family&apos;s trials and triumphs here because they tend to
                be very public so they naturally spill into our community, school, and church
                experiences. Because of this, we find it best to be open and share what is really
                going on to create context and understanding rather than confusion or judgement.
              </p>

              <p>
                Some of the stories we share of our day-to-day life may be uncomfortable to read. We
                are not sharing them to demonstrate how “hard” our life is or so that others can
                “feel bad” for us. We are comfortable with who we are as a family and what our
                reality is.
              </p>

              <p>
                All ASD parents, and parents of kids with mental illness in general, have their own
                unique challenges and this is how we&apos;ve decided to share some of ours.
              </p>
              <br />
            </div>
          </section>
        </Layout>
      );
    }}
  />
);

export default About;
